import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import SubmitButton from '../components/SubmitButton';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../components/FormHeader';
import FormVarTH from '../components/FormVarTH';
import FormMoney from '../components/FormMoney';
import useSubmitPost from '../hooks/handleSubmitPost';
import FormVarTF from '../components/FormVarTF';
import FormVarF from '../components/FormVarF';
import FormText from '../components/FormText';

const wip_list = { name: 'Wip List', href: '/wip_list', current: false };
const tabs = [wip_list];

const WipNew = () => {
  const navigate = useNavigate();
  const { key, id } = useParams();
  const [employees, setEmployees] = useState([]); // Ensure initial state is an empty array
  const handleSubmit = useSubmitPost();
  const [wip, setWip] = useState({
    id: '',
    job_number: '',
    job_site: '',
    customer_id: '',
    job_description: '',
    type: '',
    f_p_type: '',
    prev_wage: '',
    price: '',
    invoiced: '',
    paid: '',
    void: '',
    pm: '',
    tax_status: '',
    date: '',
    complete: 0,
    acc_approval: '',
  });
  const [originalValues, setOriginalValues] = useState({ invoiced: '', paid: '' });

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/wip_crud.php`;
  const [contEngineer, setContEngineer] = useState(false); 

  useEffect(() => {
    const fetchCustomer = async () => {
      if (key !== 'new') {
        try {
          const response = await axios.get(`${apiUrl}/wip_crud.php`, {
            params: {  getWipDetail: 'edit',  wip_id: id  }});
          const data = response.data;
          if (data.error) {
            toast.error(data.error, {
              position: 'top-center'
            });
          }
          setWip(data.wip_detail||[]);
          setEmployees(data.employees || []); // Ensure data.employees is an array
          setOriginalValues({ invoiced: data.wip_detail.invoiced, paid: data.wip_detail.paid });
          setContEngineer(data.wip_detail?.contract_engineer ? true : false);
        } catch (error) {
         if (error.response && error.response.status === 401) {
         toast.error("Session expired. Please login again.", {
         position: "top-center"
         });
         navigate('/login');
         } else if (error.response && error.response.status === 403) {
          navigate('/wip_list/aj');
         toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
         position: "top-center"
         });
         }
        } finally {
          // setLoading(false);
        }
      } else {
        try {
          const response = await axios.get(`${apiUrl}/wip_crud.php`, {
            params: {
              getNewWip: 'new',
              job_number: id
            }
          });
          const data = response.data;
          if (data.error) {
            toast.error(data.error, {
              position: 'top-center'
            });
            navigate('/wip_list');
          }
          setWip(data.wip_detail);
          setEmployees(data.employees || []); // Ensure data.employees is an array
        } catch (error) {
          console.log('Error fetching data', error);
          // navigate('/customers')
        } finally {
          // setLoading(false);
        }
      }
    };

    fetchCustomer();
  }, [key, id, apiUrl, navigate]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setWip((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const bodyData = {
    status: 'submit',
    key: key,
    eng_approval:true,
    data: {
      ...wip,
      originalInvoiced: originalValues.invoiced,
      originalPaid: originalValues.paid
    }
  };

  const handleToggle = () => {
    setContEngineer(!contEngineer); // Toggle the state
  };


  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5">
        <form
          onSubmit={(event) =>
            handleSubmit(event, {
              bodyData,
              api,
              errorLabel: 'Project',
              redirectPath: '/wip_list/aj'
            })
          }
          className="w-[800px] px-2 bg-white border rounded-lg py-8 px-8">
          <div className="space-y-12">
            <FormHeader type={key === 'edit' ? 'Edit' : 'New'} what={'WIP'} />

            <div className="border-b border-gray-900/10 pb-12 ">
              <h6 className="text-base font-semibold leading-7 text-gray-600">
                {key !== 'new' ? (
                  <>
                    <span className="text-gray-700">Wid:{id}</span>
                    <br />
                    <span className="text-gray-700">{wip.customer_name}</span>
                  </>
                ) : (
                  <>
                    <span className="text-gray-700">{wip.customer_name}</span>
                  </>
                )}
                <br />
                <span className="text-sm text-gray-500"> {wip.job_number}</span> <br />
                <span className="text-sm text-gray-500"> {wip.job_site}</span>
              </h6>
              <br />

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <>
                  {/* Parts & Programming */}
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="partsProgramming"
                        name="f_p_type"
                        value="P" // Set the value to a constant that identifies this choice
                        checked={wip.f_p_type === 'P'} // Checked if wip.f_p_type is 'partsProgramming'
                        onChange={handleChange}
                        type="radio"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="partsProgramming" className="font-medium text-gray-900">
                        Parts & Programming
                      </label>
                    </div>
                  </div>

                  {/* Full install */}
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="fullInstall"
                        name="f_p_type"
                        value="F" // Set the value to a constant that identifies this choice
                        checked={wip.f_p_type === 'F'} // Checked if wip.f_p_type is 'fullInstall'
                        onChange={handleChange}
                        type="radio"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="fullInstall" className="font-medium text-gray-900">
                        Full Install
                      </label>
                    </div>
                  </div>

                  <div className="relative col-span-full"></div>
                  {/* prevailing wage */}
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="prev_wage"
                        name="prev_wage"
                        checked={wip.prev_wage || ''} // This should simply be the boolean state
                        onChange={handleChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="prev_wage" className="font-medium text-gray-900">
                        Prevailing Wage
                      </label>
                    </div>
                  </div>

                  <div className="relative col-span-full"></div>

                  {/* Vendor */}
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="acc_approval"
                        name="acc_approval"
                        checked={wip.acc_approval || ''} // This should simply be the boolean state
                        onChange={handleChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="acc_approval" className="font-medium text-gray-900">
                        Vendor
                      </label>
                    </div>
                  </div>


                  {/* contract engineering */}
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="contract_engineering"
                        name="contract_engineering"
                        value={contEngineer}
                        checked={contEngineer}
                        onChange={handleToggle}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="contract_engineering" className="font-medium text-gray-900">
                        Engineering Contractor
                      </label>
                    </div>
                  </div>
                  {contEngineer && (
                    <FormVarF label={'Contract Engineer'} inside={'contract_engineer'} val={wip.contract_engineer} onChange={handleChange} />
                  )}

                  {/* contact sections */}
                  <div className="relative col-span-full py-4">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                  </div>
                  {/* contact sections */}

                  {/* type */}
                  <div className="sm:col-span-3">
                    <label htmlFor="simple-select" className="mb-2 block text-sm font-medium text-gray-700">
                      Type <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="simple-select"
                      name="type"
                      value={wip.type || ''}
                      onChange={handleChange}
                      className="block w-60 p-2.5 text-sm text-gray-900 bg-white border border-gray-300 
                      rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select your option</option>
                      <option value="New System - FA">New System FA</option>
                      <option value="New System - ARCS">New System ARCS</option>
                      <option value="Upgrade">Upgrade</option>
                      <option value="Floor Work">Floor Work</option>
                      <option value="Repair">Repair</option>
                      <option value="Filing">Filing</option>
                    </select>
                  </div>

                  {/* tax relative col-span-full py-4*/}
                  <div className="sm:col-span-3">
                    <label htmlFor="simple-select" className="mb-2 block text-sm font-medium text-gray-700">
                      Select Tax Type
                    </label>
                    <select
                      id="simple-select"
                      name="tax_status"
                      value={wip.tax_status || ''}
                      onChange={handleChange}
                      className="block w-60 p-2.5 text-sm text-gray-900 bg-white border border-gray-300 
                      rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select your option</option>
                      <option value="tax">Taxable</option>
                      <option value="CI">Capitol improvement</option>
                      <option value="CE">Contractor exempt</option>
                      <option value="EO">Exempt organization</option>
                      <option value="OE">Other Exempt</option>
                    </select>
                  </div>

                  {/* tax relative col-span-full py-4*/}
                  <div className="sm:col-span-3">
                    <label htmlFor="tax-type-select" className="mb-2 block text-sm font-medium text-gray-700">
                      Select Project Manger <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="tax-type-select"
                      name="pm"
                      value={wip.pm || ''}
                      onChange={handleChange}
                      className="block w-60 p-2.5 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">------------</option>
                      {employees.map((employee, index) => (
                        <option key={index} value={employee.pm}>
                          {employee.first_name} {employee.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>

                <div className="sm:col-span-1">
                  <label htmlFor="invoiced" className="block text-sm font-medium leading-6 text-gray-900">
                    invoiced% <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="invoiced"
                      id="invoiced"
                      maxLength="3"
                      value={wip.invoiced || ''}
                      onChange={handleChange}
                      required={true}
                      autoComplete="NY"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-1">
                  <label htmlFor="paid" className="block text-sm font-medium leading-6 text-gray-900">
                    Paid% <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="paid"
                      id="paid"
                      maxLength="3"
                      value={wip.paid || ''}
                      onChange={handleChange}
                      required={true}
                      autoComplete="NY"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <FormMoney inside={'price'} val={wip.price} onChange={handleChange} required={false} />
                <FormText label={'Job Description'} inside={'job_description'} val={wip.job_description} onChange={handleChange} />
                {/* <SelectSalesUser */}
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <NavLink to={`/proposal_list`}>
              <button type="button" className="text-sm font-semibold leading-6 text-blue-400">
               Proposal List
              </button>
            </NavLink>
            <NavLink to={`/wip_list/aj`}>
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Wip List
              </button>
            </NavLink>
            <SubmitButton disabled={!wip.f_p_type || !wip.type || !wip.pm} children={'Submit'} />
          </div>
        </form>
        
      </div>
    </>
  );
};

export default WipNew;
