import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import InspectionDetailsModal from '../components/InspectionDetailsModal';
import InspectionFormModal from '../components/InspectionFormModal';
import CalendarHeader from '../components/CalendarHeader';
import { formatTimeTo12HourUtil } from '../utils/formatTimeTo12HourUtil';


const InspectionCalendarView = () => {
  const [inspections, setInspections] = useState([]);
  const [viewMode, setViewMode] = useState('week'); // 'week' or 'month'
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // For adding/editing inspections
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false); // For viewing inspection details
  const [selectedInspection, setSelectedInspection] = useState(null); // Currently selected inspection
  const [formData, setFormData] = useState({
    maintenance_id: '',
    job_site_name: '',
    lead_tech_id: '',
    scheduled_due_date: '',
    schedule_date_to: '',
    actual_inspection_date: '',
    status: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [technicians, setTechnicians] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().getMonth() + 1
  ); // Months are 1-based
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDates, setSelectedDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getWeekStart = (date = new Date()) => {
  const dayOfWeek = date.getDay() || 7; // Sunday (0) becomes 7
  const diff = date.getDate() - dayOfWeek + 1; // Subtract dayOfWeek to get Monday
    return new Date(date.getFullYear(), date.getMonth(), diff);
  };

const getWeekDates = (startOfWeek) => {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDay = new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + i
    );
    dates.push(currentDay);
  }
  return dates;
};


  const getMonthDates = (year, month) => {
    const date = new Date(year, month - 1, 1);
    const dates = [];
    while (date.getMonth() + 1 === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

useEffect(() => {
  if (viewMode === 'week') {
    const firstDayOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
    const weekStart = getWeekStart(firstDayOfMonth);
    const weekDates = getWeekDates(weekStart);
    setSelectedDates(weekDates);
    setStartDate(weekDates[0]);
    setEndDate(weekDates[weekDates.length - 1]);
  } else {
    const monthDates = getMonthDates(selectedYear, selectedMonth);
    setSelectedDates(monthDates);

    // Set start and end dates for the month
    setStartDate(monthDates[0]);
    setEndDate(monthDates[monthDates.length - 1]);
  }

  fetchTechnicians();
}, [selectedMonth, selectedYear, viewMode, isModalOpen, isDetailModalOpen]);

useEffect(() => {
  if (startDate && endDate) {
    fetchInspections(startDate, endDate, 'start, end');
  }
}, [startDate, endDate]);


  const fetchInspections = async (startDate, endDate) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php`, {
        params: {
          getInspections: 'all',
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        },
      });
      const data = Array.isArray(response.data) ? response.data : [];
      const filteredData = data.filter(
        (inspection) => (inspection.status || '').toLowerCase() !== 'completed'
      );

      setInspections(filteredData);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTechnicians = async () => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php`, {
        params: { employee_list: 'true'},
        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', },
      });
      const employeesData = Array.isArray(response.data) ? response.data : [];
      if (employeesData.length === 0) {
        toast.error('No maintenance technicians found!', { position: 'top-center' });
      }
      setTechnicians(employeesData);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      toast.error('Session expired. Please login again.', {
        position: 'top-center',
      });
      navigate('/login');
    } else if (error.response && error.response.status === 403) {
      toast.error('Access denied.', {
        position: 'top-center',
      });
    } else {
      toast.error('An error occurred while fetching data.', {
        position: 'top-center',
      });
    }
  };

  const handleMonthChange = (e) => {
    const [year, month] = e.target.value.split('-');
    const newSelectedYear = parseInt(year, 10);
    const newSelectedMonth = parseInt(month, 10);
    setSelectedYear(newSelectedYear);
    setSelectedMonth(newSelectedMonth);
  };

  const handlePreviousWeek = () => {
    const previousWeekStart = new Date(selectedDates[0]);
    previousWeekStart.setDate(previousWeekStart.getDate() - 7);

    // Check if previous week's start date is within the selected month
    if (previousWeekStart.getMonth() + 1 === selectedMonth) {
      const weekDates = getWeekDates(previousWeekStart);
      setSelectedDates(weekDates);

      // Update startDate and endDate
      setStartDate(weekDates[0]);
      setEndDate(weekDates[weekDates.length - 1]);
    } else {
      toast.info('You have reached the beginning of the selected month.');
    }
  };

  const handleNextWeek = () => {
    const nextWeekStart = new Date(selectedDates[0]);
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);

    // Check if next week's start date is within the selected month
    if (nextWeekStart.getMonth() + 1 === selectedMonth) {
      const weekDates = getWeekDates(nextWeekStart);
      setSelectedDates(weekDates);

      // Update startDate and endDate
      setStartDate(weekDates[0]);
      setEndDate(weekDates[weekDates.length - 1]);
    } else {
      toast.info('You have reached the end of the selected month.');
    }
  };

  const handleViewChange = (e) => {
    const view = e.target.value;
    setViewMode(view);
  };

  const openModal = (isEdit = false) => {
    setIsModalOpen(true);
    setIsEditing(isEdit);
    if (!isEdit) {
      // Reset form data for new inspection
      setFormData({
        maintenance_id: '',
        job_site_name: '',
        lead_tech_id: '',
        scheduled_due_date: '',
        schedule_date_to: '',
        actual_inspection_date: '',
        status: '',
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, {
        data: formData,
        status: 'recordInspection',
      });
      if (response.data.success) {
        toast.success('Inspection saved successfully!');
        closeModal();
        fetchInspections(); // Refresh the inspections list
      } else {
        toast.error(response.data.error || 'Failed to save inspection.');
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleEventClick = (inspection) => {
    setSelectedInspection(inspection);
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const handleEditInspection = () => {
    setFormData(selectedInspection);
    setIsDetailModalOpen(false);
    setIsModalOpen(true);
    setIsEditing(true);
  };

  const handleCellClick = (date, technician) => {
    openModal(false); // Open modal for adding new inspection
    setFormData((prevData) => ({
      ...prevData,
      scheduled_due_date: date.toISOString().split('T')[0],
      schedule_date_to: date.toISOString().split('T')[0], // Default to the same day
      lead_tech_id: technician.id || '',
      // You may set other default values as needed
    }));
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <HeadingCp label={'Inspection Calendar View'} />

      {/* Month Selector */}
      <div className="flex justify-between items-start mb-3">
        {/* Month Selector */}
        <div className="flex items-center">
          <label htmlFor="month-selector" className="mr-2">
            Select Month:
          </label>
          <input
            type="month"
            id="month-selector"
            value={`${selectedYear}-${String(selectedMonth).padStart(2, '0')}`}
            onChange={handleMonthChange}
            className="rounded-md border border-gray-300 py-2 px-4"
          />
        </div>
        {/* View Mode Selector */}
        <div className="flex items-center">
          <select
            id="view-mode"
            className="rounded-md border border-gray-300 py-2 px-4"
            value={viewMode}
            onChange={handleViewChange}
          >
            <option value="week">Week View</option>
            <option value="month">Month View</option>
          </select>
        </div>
      </div>

      {/* Week Navigation */}
      {viewMode === 'week' && (
        <CalendarHeader
          viewMode={viewMode}
          selectedWeek={selectedDates}
          handlePreviousWeek={handlePreviousWeek}
          handleNextWeek={handleNextWeek}
        />
      )}

      {/* Add Inspection Button */}
      <div className="mb-4 space-x-4">
        <button
          type="button"
          onClick={() => openModal(false)}
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                      shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        >
          New Inspection +
        </button>
      
        <button
          type="button"
          onClick={() => navigate(`/maintenance`)}
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                      shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Contracts
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="overflow-auto">
        <div
          className="grid"
          style={{ gridTemplateColumns: `200px repeat(${selectedDates.length}, 1fr)` }}
        >
          {/* Header Row */}
          <div className="bg-gray-100 sticky top-0 z-10">
            {/* Empty cell at the top-left corner */}
          </div>
          {selectedDates.map((date, index) => (
            <div
              key={index}
              className="bg-gray-100 text-center p-2 sticky top-0 z-10 border"
            >
              {date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' })}
            </div>
          ))}

          {/* Technician Rows */}
          {technicians.map((technician, rowIndex) => (
            <React.Fragment key={technician.id || rowIndex}>
              {/* Technician Label */}
              <div className="border p-2 bg-white sticky left-0 z-10">
                <p className="font-bold text-black">
                  {technician.first_name} {technician.last_name}
                </p>
              </div>
              {/* Cells for each date */}
              {selectedDates.map((date, colIndex) => {
                // Define currentDate here
                const currentDate = new Date(date);
                currentDate.setHours(0, 0, 0, 0);
                
                // Convert IDs to strings for accurate comparison
                const technicianId = String(technician.id);

                // Helper function to parse 'YYYY-MM-DD' and create a Date object in local time
                const parseLocalDate = (dateString) => {
                  const [year, month, day] = dateString.split('-').map(Number);
                  // Create the date using year, month-1, and day directly
                  return new Date(year, month - 1, day, 0, 0, 0);
                };

                // Use parseLocalDate instead of new Date(...) for scheduled dates
                const scheduledInspections = inspections.filter((inspection) => {
                  const fromDate = parseLocalDate(inspection.scheduled_due_date);
                  const toDate = parseLocalDate(inspection.schedule_date_to || inspection.scheduled_due_date);

                  fromDate.setHours(0, 0, 0, 0);
                  toDate.setHours(23, 59, 59, 999);

                  const inspectionLeadTechId = String(inspection.lead_tech_id);
                  return (
                    inspectionLeadTechId === technicianId &&
                    currentDate >= fromDate &&
                    currentDate <= toDate
                  );
                });

                const isScheduled = scheduledInspections.length > 0;

                return (
                  <div
                    key={`${technician.id || rowIndex}-${colIndex}`}
                    className={`border h-16 cursor-pointer hover:bg-slate-100 ${ isScheduled ? 'bg-blue-200': 'bg-white'  }`}
                    onClick={() =>
                      isScheduled
                        ? handleEventClick(scheduledInspections[0])
                        : handleCellClick(currentDate, technician)
                    }>
                    {/* Optionally, display more info in the cell */}
                    {isScheduled && (
                      <>
                      <p className="font-bold text-xs text-center">
                        {scheduledInspections[0]?.job_site_address ? scheduledInspections[0].job_site_address : 'Unnamed Inspection'}
                      </p>
                      <p className="text-green-600 text-xs text-center">
                        {scheduledInspections[0]?.customer_name? scheduledInspections[0].customer_name : 'customer missing'}</p>
                      <p className="text-gray-600 text-xs text-left pl-1">
                        {scheduledInspections[0]?.schedule_time_in ? formatTimeTo12HourUtil(scheduledInspections[0].schedule_time_in) : 'no time'}
                        {scheduledInspections[0]?.schedule_time_out ? " - "+ formatTimeTo12HourUtil(scheduledInspections[0].schedule_time_out ): 'no time'}
                      </p>
                      </>                 
                    )}
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Modals */}
      <InspectionFormModal
        isOpen={isModalOpen}
        onClose={closeModal}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleFormSubmit}
        technicians={technicians}
        apiUrl={apiUrl}
      />

      <InspectionDetailsModal
        isOpen={isDetailModalOpen}
        onClose={closeDetailModal}
        inspection={selectedInspection}
        onEdit={handleEditInspection}
      />
    </div>
  );
};

export default InspectionCalendarView;
