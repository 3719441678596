import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadingCp from '../components/HeadingCp';
import MonthTabs from '../components/MonthTabs';

const InspectionsPage = () => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = useUserData();
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(() => {
    const date = new Date();
    return String(date.getMonth() + 1).padStart(2, '0');
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;
  const months = [
      { name: 'All', value: 'all' },
      { name: 'January', value: '01' },
      { name: 'February', value: '02' },
      { name: 'March', value: '03' },
      { name: 'April', value: '04' },
      { name: 'May', value: '05' },
      { name: 'June', value: '06' },
      { name: 'July', value: '07' },
      { name: 'August', value: '08' },
      { name: 'September', value: '09' },
      { name: 'October', value: '10' },
      { name: 'November', value: '11' },
      { name: 'December', value: '12' },
    ];

  useEffect(() => {
    fetchInspections();
  }, []);

  const fetchInspections = async (selectedMonth = currentMonth) => {
  setLoading(true);
  try {
    const response = await axios.get(api, {
      params: {
        getInspections: 'all',
        month: selectedMonth,
      },
    });
    console.log(response.data);
    
    if (response.data) {
      setInspections(response.data);
    } else {
      console.error('Error fetching inspections');
    }
  } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied.", {
          position: "top-center",
        });
      } else {
        toast.error("Error fetching inspections", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (monthValue) => {
    setCurrentMonth(monthValue);
    fetchInspections(monthValue);
  };



  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
      <HeadingCp label="Inspections" />
      {/* Month Tabs */}
      <MonthTabs
        months={months}
        currentMonth={currentMonth}
        onMonthChange={handleMonthChange}
      />      
      <br/>
    <div className="flex space-x-3 items-center mb-10">
     <button
          onClick={() => navigate(`/maintenance`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-green-600 hover:bg-green-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
          Contracts
      </button>   
      </div>

      {loading ? (
        <LoadingSpinner>Loading...</LoadingSpinner>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
          <thead>
            <tr className="divide-x divide-gray-400">
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Inspection site | Customer
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Schedule Contact
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Report
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Scheduled Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Actual Inspection Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Status
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Tech Notes
              </th>
              {/* Add other headers as needed */}
            </tr>
          </thead>
          <tbody>
            {inspections.map((inspection, index) => (
              <tr key={inspection.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <span className='font-bold text-black'>
                      {inspection.job_site_address}
                      {inspection.job_site_city && (' '+ inspection.job_site_city + ', ')} 
                      {inspection.job_site_state && (' '+ inspection.job_site_state + ' ')} 
                      {inspection.job_site_zip && (' '+ inspection.job_site_zip)} 
                  </span>
                  <p className="text-sm text-gray-400">{inspection.customer_name}</p>
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                      {inspection.contact_name}
                      <p>
                        {inspection.contact_phone && (' '+ inspection.contact_phone + ', ')} 
                      </p>
                      <p>
                        {inspection.contact_email && (' '+ inspection.contact_email + ' ')}
                      </p>
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  {/* file */}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <DateFormat date={inspection.scheduled_due_date} />
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <DateFormat date={inspection.actual_inspection_date} />
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  {inspection.status}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  {inspection.tech_notes}
                </td>
                {/* <td className="px-3 py-4 text-sm text-gray-500">
                  <button onClick={() => navigate(`/inspection/${inspection.id}`)}>
                    {inspection.id}
                  </button>
                </td> */}

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InspectionsPage;
