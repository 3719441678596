import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import SubmitButton from '../components/SubmitButton';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../components/FormHeader';
import FormVarTH from '../components/FormVarTH';
import FormMoney from '../components/FormMoney';
import useSubmitPost from '../hooks/handleSubmitPost';
import FormVarF from '../components/FormVarF';
import FormCity from '../components/FormCity';
import FormState from '../components/FormState';
import FormZip from '../components/FormZip';
import FormVarH from '../components/FormVarH';
import FormText from '../components/FormText';
import FormVarTF from '../components/FormVarTF';
import FormPhone from '../components/FormPhone';
import SelectCustomerMaintenance from '../components/SelectCustomerMaintenance';

const MaintenanceContractNew = () => {
  const navigate = useNavigate();
  const { key, id } = useParams();
  const handleSubmit = useSubmitPost();
  const [errors, setErrors] = useState({});

  const [jobSite, setJobSite] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  
  // Updated contract state to include all fields from the updated table
  const [contract, setContract] = useState({
    id: '',
    customer_id: '',
    start_year: '',
    contract_type: '',
    job_site_address: '',
    job_site_address2: '',
    city: '',
    state: '',
    zip_code: '',
    frequency: '',
    hours: '',
    notes: '',
    system_type: '',
    current_price: '',
    exp_date_contract: '',
    auto_renew: 0,
    status: '',
    terms_begin: '',
    signed_date: '',
    file: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    last_inspection_date: '',
    due_dates_shifted: 0,
    price: ''
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;

  useEffect(() => {
    const fetchContract = async () => {
      if (key !== 'new') {
        try {
          const response = await axios.get(api, {
            params: { getContractDetail: true, contract_id: id },
          });
          
          const data = response.data;
          if (data === 'error') {
            toast.error(data.error, {
              position: 'top-center',
            });
          } else {
            // Assuming data[1] has all updated fields matching state keys
            setContract(data[1] || {});
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast.error('Session expired. Please login again.', {
              position: 'top-center',
            });
            navigate('/login');
          } else if (error.response && error.response.status === 403) {
            navigate('/contracts_list');
            toast.error("You don't have access to this area.", {
              position: 'top-center',
            });
          }
        }
      } else {
        try {
          const response = await axios.get(api, {
            params: {
              getNewContract: true,
              customer_id: id,
            },
          });
          const data = response.data;
          if (data.error) {
            toast.error(data.error, {
              position: 'top-center',
            });
            navigate('/contracts_list');
          } else {
            setContract(data.contract_detail || {});
          }
        } catch (error) {
          console.log('Error fetching data', error);
        }
      }
    };

    fetchContract();
  }, [key, id, api, navigate]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;

    // Copy of current errors
    let newErrors = { ...errors };

    // Validate Email
    if (name === 'contact_email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (newValue && !emailRegex.test(newValue)) {
        newErrors.contact_email = 'Invalid email address.';
      } else {
        delete newErrors.contact_email;
      }
    }

    if (name === 'contact_phone') {
      const phoneRegex = /^[0-9]{0,10}$/; // allows 0 to 10 digits only
      if (newValue && !phoneRegex.test(newValue)) {
        newErrors.contact_phone = 'Invalid phone number. Use up to 10 digits only.';
      } else {
        delete newErrors.contact_phone;
      }
    }

    // Validate Zip Code (US format)
    if (name === 'job_site_zip') {
      const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/; 
      if (newValue && !zipRegex.test(newValue)) {
        newErrors.job_site_zip = 'Invalid zip code (use 5 digits or ZIP+4 format).';
      } else {
        delete newErrors.job_site_zip;
      }
    }

    setContract((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    setErrors(newErrors);
  };

  const handleCompanySelect = (newJobSite) => {
    console.log('Selected company:', newJobSite.customer_name);
    
    if (newJobSite && newJobSite.id) {
      setSelectedCompany(newJobSite);
      setContract((prev) => ({
        ...prev,
        customer_name: newJobSite.customer_name,
        customer_id: newJobSite.id,
      }));
    } else {
     
    }
  };  

  const bodyData = {
    status: 'submit',
    key: key,
    data: {
      ...contract,
    },
  };

  // Required fields logic (adjust if necessary)
  const isSubmitDisabled =
    !contract.customer_id ||
    !contract.job_site_address ||
    !contract.job_site_city ||
    !contract.job_site_state ||
    !contract.frequency ||
    !contract.contact_name ||
    !contract.contact_phone ||
    !contract.contact_email;

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5">
        <form
          onSubmit={(event) =>
            handleSubmit(event, {
              bodyData,
              api,
              errorLabel: 'Contract',
              redirectPath: '/contracts_list',
            })
          }
          className="w-[800px] bg-white border rounded-lg py-8 px-8"
        >
          <div className="space-y-12">
            <FormHeader type={key === 'edit' ? 'Edit' : 'New'} what={'Maintenance Contract'} />

            <div className="border-b border-gray-900/10 pb-12">
              <h6 className="text-base font-semibold leading-7 text-gray-600">
                {key !== 'new' && (
                  <>
                    <span className="text-gray-700">
                      Contract ID: {id}
                    </span>
                    <br />
                    <span className="text-gray-700">Customer ID: {contract.id}</span>
                  </>
                )}
              </h6>
              <br />

              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"> 
                
                {/* Auto Renew (Checkbox) */}
                <div className="col-span-full flex items-center">
                  <input
                    type="checkbox"
                    id="auto_renew"
                    name="auto_renew"
                    checked={contract.auto_renew === 1}
                    onChange={handleChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="auto_renew" className="ml-2 block text-sm font-medium text-gray-900">
                    Auto Renew
                  </label>
                </div>      

                {/* Signed Date */}
                <div className="sm:col-span-2">
                  <label htmlFor="signed_date" className="block text-sm font-medium text-gray-900 py-1">
                    Signed Date
                  </label>
                  <input
                    type="date"
                    id="signed_date"
                    name="signed_date"
                    value={contract.signed_date || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                              focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                {/* Expiration Date Contract */}
                {contract.auto_renew !== 1 && (
                  <div className="sm:col-span-2">
                    <label htmlFor="exp_date_contract" className="block text-sm font-medium text-gray-900 py-1">
                      Expiration Date
                    </label>
                    <input
                      type="date"
                      id="exp_date_contract"
                      name="exp_date_contract"
                      value={contract.exp_date_contract || ''}
                      onChange={handleChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                                focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                )}

                {/* Start Year */}
                <FormVarTF 
                  label={'Start Year'} 
                  inside={'start_year'} 
                  val={contract.start_year} 
                  onChange={handleChange} 
                />

                {/* Contract Type */}
                <div className="sm:col-span-2">
                  <label htmlFor="contract_type" className="block text-sm font-medium text-gray-900 py-1">
                    Contract Type
                  </label>
                  <select
                    id="contract_type"
                    name="contract_type"
                    value={contract.contract_type || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Select Contract Type</option>
                    <option value="Base Building">Base Building</option>
                    <option value="Tenant">Tenant</option>
                    <option value="Sub-System">Sub-System</option>
                    <option value="Pre-Action">Pre Action</option>
                  </select>
                </div>                

                {/* Select Job Site */}
                {contract.customer_name ? (
                  <div className="col-span-full">
                  <label htmlFor="customer_name" className="block text-sm font-medium text-gray-900">
                    Customer Name <span className="text-red-500">*</span> | Note: by clearing the job site field (activates the search engine)
                  </label>
                  <input
                    type="text"
                    id="customer_name"
                    name="customer_name"
                    value={contract.customer_name || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                ) : (
                  <div className="sm:col-span-3">
                    <SelectCustomerMaintenance
                      label="Select Customer Name"
                      items={jobSite}
                      selectedItem={selectedCompany}
                      setSelectedItem={handleCompanySelect}
                      apiUrl={`${apiUrl}/contracts_crud.php`}
                    />
                  </div>
                )}

                {/* Job Site Address */}
                <FormVarH
                  label={'Job Site Address'}
                  inside={'job_site_address'}
                  val={contract.job_site_address}
                  onChange={handleChange} />

                {/* Job Site Address2 */}
                <FormVarH
                  label={'Address2'}
                  inside={'job_site_address2'}
                  val={contract.job_site_address2}
                  onChange={handleChange}
                  required={false}
                />

                {/* Job Site City */}
                <FormCity
                  val={contract.city}
                  onChange={handleChange}
                />

                {/* Job Site State */}
                <FormState
                  val={contract.state}
                  onChange={handleChange}
                />

                {/* Job Site Zip */}
                <FormZip
                  val={contract.zip_code}
                  onChange={handleChange}
                  type="number"
                />

                {/* System Type */}
                <FormVarF
                  label={'System Type'}
                  inside={'system_type'}
                  val={contract.system_type}
                  onChange={handleChange}
                />

                {/* Frequency */}
                <div className="sm:col-span-2">
                  <label htmlFor="frequency" className="block text-sm font-medium text-gray-900 py-1">
                    Frequency
                  </label>
                  <select
                    id="frequency"
                    name="frequency"
                    value={contract.frequency || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Select Frequency</option>
                    <option value="1">Annually</option>
                    <option value="6">Semi-Annually</option>
                    <option value="3">Quarterly</option>
                    <option value="12">Monthly</option>
                  </select>
                </div>

                {/* Hours */}
                <FormVarTF
                  label={'Hours'}
                  inside={'hours'}
                  val={contract.hours}
                  onChange={handleChange}
                  type="number"
                  step="0.01"
                  required={true}
                />

                {/* Notes */}
                <FormText 
                  label={'Notes'} 
                  inside={'notes'} 
                  val={contract.notes} 
                  onChange={handleChange} 
                />

                {/* Current Price */}
                <FormMoney
                  inside={'current_price'}
                  val={contract.current_price}
                  onChange={handleChange}
                  required={false}
                />

                {/* Terms Begin (Months select) */}
                <div className="sm:col-span-2">
                  <label htmlFor="terms_begin" className="block text-sm font-medium text-gray-900 py-1">
                    Terms Begin (Month)
                  </label>
                  <select
                    id="terms_begin"
                    name="terms_begin"
                    value={contract.terms_begin || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>

                {/* Contact Name */}
                <FormVarH
                  label={'Contact Name'}
                  inside={'contact_name'}
                  val={contract.contact_name}
                  onChange={handleChange}
                />

                {/* Contact Phone */}
                <FormPhone
                label={'Contact Phone'}
                  inside={'contact_phone'}
                  val={contract.contact_phone}
                  onChange={handleChange}
                />
                {errors.contact_phone && (<h6 className='text-xs text-red-500'>{errors.contact_phone} </h6>)}

                {/* Contact Email */}
                <FormVarH
                  label={'Contact Email'}
                  inside={'contact_email'}
                  val={contract.contact_email}
                  onChange={handleChange}
                  required={false}
                />
                  {errors.contact_email && (<h6 className='text-xs text-red-500'>{errors.contact_email}</h6>)}

                {/* Last Inspection Date */}
                <div className="sm:col-span-2">
                  <label htmlFor="last_inspection_date" className="block text-sm font-medium text-gray-900 py-1">
                    Last Inspection /FDNY Test Date
                  </label>
                  <input
                    type="date"
                    id="last_inspection_date"
                    name="last_inspection_date"
                    value={contract.last_inspection_date || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm 
                              focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <NavLink to={`/maintenance`}>
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Contracts List
              </button>
            </NavLink>
            <SubmitButton disabled={isSubmitDisabled}>Submit</SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default MaintenanceContractNew;
